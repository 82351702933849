<template>
    <d-container fluid>
        <pageTitle subtitle="Fotostudio"
                   title="Voertuig Status"/>
        <d-row>
            <vCard :col="12" header="Voertuig status" fluid :actions="cardAction">
                <vTable :fields="fields" :actions="actions" :data="process" :per-page="999"/>
            </vCard>
            <vCard :col="3" header="Platform control">
                <div class="flex-column">
                    <d-button @click="rotatePlatform(10)"><i class="material-icons">arrow_left</i></d-button>
                    <d-button @click="rotatePlatform( 30)"><i class="material-icons">keyboard_double_arrow_left</i>
                    </d-button>
                    <d-button @click="rotatePlatform(-10)"><i
                        class="material-icons">keyboard_double_arrow_right</i></d-button>
                    <d-button @click="rotatePlatform(-30)"><i class="material-icons">arrow_right</i></d-button>
                    <br/>
                    <br/>
                    <d-input placeholder="graden" v-model="platformControlDegrees"/>
                    <br/>
                    <d-button @click="rotatePlatform(platformControlDegrees)" class="pull-right right"><i
                        class="material-icons">switch_right</i> roteren
                    </d-button>
                </div>
            </vCard>
            <vCard :col="6">
                <testCapture />
                <br>
                <restartPlc />
                <br>
                <restartSortingServiceButton />
            </vCard>
            <vCard :col="3" header="Status legenda">
                <table>
                    <tr v-for="option in legendaStatusOptions">
                        <td>
                            <span class="status-bubble" :class="'status-bubble__' + option.status"/>
                        </td>
                        <td>
                            <span>{{ option.description }}</span>
                        </td>
                    </tr>
                </table>
            </vCard>
            <addProcessModal :modal="modal" @closeHandler="modalCloseHandler"/>
            <processOptionsModal :modal="processOptionsModalData"
                                 @openExteriorHandler="() => { exteriorModal.open = true }"
                                 @closeHandler="processOptionsModalCloseHandler"
                                 @deleteProcess="$store.dispatch('photoStudio.studio/refreshProcess')"/>
            <exteriorViewerModal :modal="exteriorModal" @closeHandler="() => {exteriorModal.open = false}"/>
        </d-row>
    </d-container>
</template>

<script>
import {ContentLoader} from 'vue-content-loader'
import {mapState} from 'vuex'
import addProcessModal from '../modals/addProcess'
import processOptionsModal from '../modals/processOptions'
import exteriorViewerModal from '../modals/exteriorViewerModal'
import testCapture from '../components/testCapture'
import restartPlc from '../components/restartPlc'
import restartSortingServiceButton from '../components/restartSortingServiceButton'

export default {
    title: 'Studio overzicht',
    components: {ContentLoader, addProcessModal, processOptionsModal, exteriorViewerModal, testCapture, restartPlc, restartSortingServiceButton},
    data() {
        return {
            platformControlDegrees: null,
            cardAction: [
                {
                    icon: '<i class="material-icons">add</i>',
                    fn: () => {
                        this.modal.open = true
                    }
                },
                {
                    icon: '<i class="material-icons">refresh</i>',
                    fn: () => {
                        this.$store.dispatch('photoStudio.studio/refreshProcess')
                    }
                }
            ],
            actions: [
                {
                    icon: '<i class="material-icons">photo</i>',
                    fn: (vehicle) => {
                        this.$router.push({
                            name: 'dashboard/inname/photoStudio/sort', params: {
                                licensePlate: vehicle.licenseplate
                            }
                        })
                    }
                },
                {
                    icon: '<i class="material-icons">directions_car</i>',
                    fn: (vehicle) => {
                        window.open(`https://intranet.vaartland.nl/vvi/opslaan_kenteken.php?kenteken=${vehicle.licenseplate}`, '_blank').focus();

                    }
                },
                {
                    icon: '<i class="material-icons">settings</i>',
                    fn: (vehicle) => {
                        this.processOptionsModalData.open = true
                        this.processOptionsModalData.vehicle = vehicle
                        this.exteriorModal.vehicle = vehicle
                    }
                }
            ],
            fields: [
                {
                    name: 'licenseplate',
                    title: 'Kenteken',
                    sortField: 'licenseplate',
                    formatter: (e) => {
                        return this.$options.filters.licensePlate(e)
                    }
                },
                {
                    name: 'stock_option',
                    title: 'Voorraad optie',
                    sortField: 'stock_option',
                    formatter: (e) => {
                        return e !== null ? e.replace('Vaartland.nl', '') : e
                    }
                },
                {
                    name: 'transportPreferenceHeerenveen',
                    title: 'Transport Heerenveen',
                    sortField: 'transportPreferenceHeerenveen',
                    formatter: (e) => {
                        switch (e) {
                            case 'Geschikt voor Heerenveen':
                                return `<span style="color: green;">Geschikt voor Heerenveen</span>`
                            case 'Liever niet naar Heerenveen':
                                return `<span style="color: orange;">Liever niet naar Heerenveen</span>`
                            case 'Niet naar Heerenveen':
                                return `<span style="color: red;">Niet naar Heerenveen</span>`
                            default:
                                return `<span>${e ? e : ''}</span>`
                        }
                        
                    }
                },
                {
                    name: 'is_polished',
                    title: 'Gepoetst',
                    formatter: (e) => {
                        if (e === '1') {
                            return `<span class="status-bubble status-bubble__success"></span>`
                        } else {
                            return `<span class="status-bubble status-bubble__error"></span>`
                        }
                    }
                },
                {
                    name: 'technical_ready',
                    title: 'Technisch',
                    formatter: (e) => {
                        if (e === '1') {
                            return `<span class="status-bubble status-bubble__success"></span>`
                        } else {
                            return `<span class="status-bubble status-bubble__error"></span>`
                        }
                    }
                },
                {
                    name: 'optical_ready',
                    title: 'Optisch',
                    formatter: (e) => {
                        if (e === '1') {
                            return `<span class="status-bubble status-bubble__success"></span>`
                        } else {
                            return `<span class="status-bubble status-bubble__error"></span>`
                        }
                    }
                },
                {
                    name: 'exterior',
                    title: 'Exterieur',
                    formatter: (e) => {
                        return `<span class="status-bubble status-bubble__${e}"></span>`
                    }
                },
                {
                    name: 'interior',
                    title: 'Interieur',
                    formatter: (e) => {
                        return `<span class="status-bubble status-bubble__${e}"></span>`
                    }
                },
                {
                    name: 'details',
                    title: 'Details',
                    formatter: (e) => {
                        return `<span class="status-bubble status-bubble__${e}"></span>`
                    }
                },
                'actions'
            ],
            legendaStatusOptions: [
                {status: 'done', description: 'Stap afgerond'},
                {status: 'uploading', description: 'Uploaden van foto\'s'},
                {status: 'processing', description: 'Stap wordt verwerkt'},
                {status: 'unprocessed', description: 'Er is nog niks gedaan'},
                {status: 'error', description: 'Er is iets fouts gegaan'}
            ],
            modal: {
                open: false
            },
            processOptionsModalData: {
                open: false,
                vehicle: null
            },
            exteriorModal: {
                open: false,
                vehicle: null
            }
        }
    },
    methods: {
        rotatePlatform(degrees) {
            this.$store.dispatch('photoStudio.studio/rotatePlatform', {degrees: degrees})
        },
        goToSort(licensePlate) {
            this.$router.push({
                name: 'dashboard/inname/photoStudio/sort',
                params: {licensePlate: licensePlate}
            })
        },
        modalCloseHandler() {
            this.modal.open = false
            this.$store.dispatch('photoStudio.studio/refreshProcess')
        },
        processOptionsModalCloseHandler() {
            this.processOptionsModalData.open = false
        }
    },
    computed: {
        ...mapState('photoStudio.studio', ['process'])
    }
}
</script>

<style lang="scss">
.status-bubble {
    display: block;
    width: 0;
    height: 0;
    padding: 8px;
    margin-right: 10px;
    border-radius: 8px;

    &__done,
    &__success {
        background-color: green;
    }

    &__null,
    &__error {
        background-color: red;
    }

    &__uploading {
        background-color: darkblue;
    }

    &__warning {
        background-color: orange;
    }

    &__processed,
    &__processing {
        background-color: blue;
    }

    &__unprocessed {
        background-color: orange;
    }
}

.status-bubble:nth-last-child(1) {
    margin-right: 0;
}
</style>
